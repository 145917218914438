import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Text } from "@prism/psm-ui-components";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import TimePicker from "web/common/TimeRange/TimePicker";
import { ClickOutside } from "core/utils/clickOutsideUtils/clickOutside";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { getHotelItinerary } from "../hotelItinerary/hotelItinerarySelectors";
import { selectCheckInOutDateTimeCommonState } from "./hotelCheckInOutSelectors";
import { LayoutCluster } from "@/layout/culster/Cluster";

const HotelCheckInOutTimeComponent = props => {
  const {
    isHidden,
    componentId,
    displayTime,
    originalDateTime,
    changeTime,
    isEditing,
    setIsEditing,
    time,
    setTime,
    petId,
  } = props;

  useEffect(() => {
    setIsEditing(false);
  }, [petId, originalDateTime?.format()]);

  const ref = useRef(null);

  const onClickOutside = () => {
    changeTime(moment(time, "YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
  };

  ClickOutside(ref, onClickOutside);

  if (!isHidden) {
    return (
      <LayoutCluster
        id={componentId}
        space="scale-S2"
        style={{ justifyContent: "flex-end" }}
        flexWrap="flex-noWrap"
      >
        {!isEditing ? (
          <Text>{displayTime}</Text>
        ) : (
          <div ref={ref}>
            <TimePicker
              time={time}
              onChange={val => {
                const m = moment(val, "HH:mm");
                const selectedDateTime = moment(originalDateTime).set({
                  hour: m.hour(),
                  minutes: m.minute(),
                });
                setTime(selectedDateTime);
              }}
            />
          </div>
        )}
      </LayoutCluster>
    );
  }

  return null;
};

export const HotelCheckInTime = connect(
  (state, { time, changeTime, setTime, isEditing, setIsEditing }) => {
    const petId = getCurrentPet(state);
    const storeNumber = getHotelItinerary(state)?.storeNumber;
    const { startDateTime } = selectCheckInOutDateTimeCommonState(state, {
      petId,
      storeNumber,
    });

    return {
      componentId: "HotelCheckInTime",
      isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
      displayTime: startDateTime && startDateTime.format("LT"),
      originalDateTime: startDateTime,
      changeTime,
      isEditing,
      setIsEditing,
      time,
      setTime,
      petId,
    };
  },
)(HotelCheckInOutTimeComponent);

export const HotelCheckOutTime = connect(
  (state, { time, changeTime, setTime, isEditing, setIsEditing }) => {
    const petId = getCurrentPet(state);
    const storeNumber = getHotelItinerary(state)?.storeNumber;
    const { endDateTime } = selectCheckInOutDateTimeCommonState(state, {
      petId,
      storeNumber,
    });

    return {
      componentId: "HotelCheckOutTime",
      isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
      displayTime: endDateTime && endDateTime.format("LT"),
      originalDateTime: endDateTime,
      changeTime,
      isEditing,
      setIsEditing,
      time,
      setTime,
      petId,
    };
  },
)(HotelCheckInOutTimeComponent);
