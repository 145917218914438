import { createSelector } from "reselect";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import moment from "moment";
import { find, get, getOr, isEmpty, compact, filter, matchesProperty, compose } from "lodash/fp";
import { engagementTypes } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { serviceTypeIds } from "core/constants/serviceTypesConstants";
import customersActionTyeps from "@/core/actionTypes/customersActionTypes";
import formatPhoneNumberNANP from "@/core/utils/stringManipulationUtils/formatPhoneNumber";
import normalizeArrayByProperty from "@/core/utils/normalizeUtils/normalizeArray";
import getUnknownPetImage from "@/core/utils/assetUtils/unknownPetImage";
import calculateAge from "@/core/utils/dateUtils/calculateAge";
import getUSTimeStringByDate from "@/core/utils/dateUtils/USTimeByDate";
import { absenceTypes, DEFAULT_BREAK_HOUR } from "@/core/constants/absencesContants";
import { getSalonHours } from "@/core/selectors/salonHoursSelector";
import { getProps, getState } from "@/core/selectors/commonSelector";
import { getBreedsList, getBreeds } from "@/core/selectors/enumsSelectors";
import { getStoreNumber, getCurrentPet } from "@/core/selectors/persistentSelectors";
import { getIsPhonePreferred } from "@/core/utils/customerProfile";
import { dayTypes } from "@/core/constants/associateSchedulingConstants";
import { getApiError, isDuplicateEmail, isDuplicatePhone } from "@/core/selectors/utils";
import { APPOINTMENT_STATUS } from "@/core/constants";
import { absenceReasons as absenceReasonsConstants } from "@/core/constants/schedulesConstants";
import normalizeFormInput from "@/core/utils/normalizeUtils/normalizeFormInput";
import getISODateWithTime from "@/core/utils/dateUtils/ISODateWithTime";
import filterUpcomingAndHereBySearchText from "@/core/utils/entitiesUtils/filterUpcomingAndHereBySearchText";
import getCustomerByPetServiceItem from "@/core/utils/entitiesUtils/getCustomerByPetServiceItem";
import filterIsBoarded from "@/core/utils/entitiesUtils/filterIsBoarded";
import filterValidDailyAppointments from "@/core/utils/entitiesUtils/filterValidDailyAppointments";
import groupBy from "@/core/utils/arrayUtils/groupBy";
import arraysToObject from "@/core/utils/arrayUtils/arraysToObject";
import {
  getHereOrCompletedFilterApplied,
  getUpcomingSearchText,
  getHereSearchText,
  getUpcomingBoardedGuestChecked,
  getHereBoardedGuestChecked,
} from "@/core/selectors/upcomingAndHereSelectors";
import {
  ACTIVE_IN_PRISM_ID,
  DUPLICATE_PHONE_USER_FRIENDLY_ERROR_MESSAGE,
  DUPLICATE_EMAIL_USER_FRIENDLY_ERROR_MESSAGE,
} from "@/core/constants/customerProfileConstants";
import parseAlertData from "@/core/utils/entitiesUtils/parseAlertData";
import convertDateToTimestamp from "@/core/utils/dateUtils/dateToTimestamp";
import getTimestampDiff from "@/dux/utils/dates/getTimestampDiff";

/**
 *  Selector to get all entites state
 *  @memberOf Selectors.Entities
 *  @function
 *  @name selectEntities
 *  @param {Object } state - redux state
 *  @returns {Object}
 */
export const selectEntities = createSelector([getState], state => state?.entities ?? {});

/**
 *  Selector to get all Customers from the Redux State
 *
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getCustomers
 *  @param {Object } state - redux state
 *  @returns {Object}
 *  @Todo Move to dux/entities/customersSelectors.js
 *  @example
 *
 *  getCustomers(state)
 */
export const getCustomers = state => state?.entities?.customers;

// TODO: move state.error selectors out of entities file
// TODO, research what these selectors are actually doing
export const getCreateCustomerError = state => state.error[customersActionTyeps.CREATE_CUSTOMER];
export const getUpdateCustomerError = state => state.error[customersActionTyeps.UPDATE_CUSTOMER];
export const getCreateCustomerPhoneError = state =>
  state.error[customersActionTyeps.CREATE_CUSTOMER_PHONE];
export const getUpdateCustomerPhoneError = state =>
  state.error[customersActionTyeps.UPDATE_CUSTOMER_PHONE];
export const getCreateCustomerEmailError = state =>
  state.error[customersActionTyeps.CREATE_CUSTOMER_EMAIL];
export const getUpdateCustomerEmailError = state =>
  state.error[customersActionTyeps.UPDATE_CUSTOMER_EMAIL];

/**
 *  Selector to get all Pets from the Redux State
 *
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getPets
 *  @param {Object } state - redux state
 *  @returns {Object}
 *  @Todo Move to dux/entities/petsSelectors.js
 *  @example
 *
 *  getPets(state)
 */
export const getPets = state => state?.entities?.pets;

/**
 *  Selector to get a pet by Pet ID
 *
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getPet
 *  @param {Object } state - redux state
 *  @param {Object } props - all additional props such as { petId }
 *  @returns {Number}
 *  @Todo Move to dux/entities/petsSelectors.js
 *  @example
 *
 *  getPet(state, { petId })
 */
export const getPet = createSelector(
  [getPets, (state, props) => props],
  (pets = {}, { petId } = {}) => petId && pets[petId],
);

/**
 *  Selector to get all Pet Ids by Customer key from state.entities.customers[customerKey].pets
 *
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getPetsByCustomer
 *  @param {Object } state - redux state
 *  @param {Object } props - all additional props such as { customerKey }
 *  @returns {Array}
 *  @Todo Move to dux/entities/petsSelectors.js
 *  @example
 *
 *  getPetsByCustomer(state, {customerKey})
 */
export const getPetsByCustomer = (state, props) => state.entities.customers[props.customerKey].pets;

/**
 *  Selector to get Salon itineraries from the Redux State
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getItineraries
 *  @param {Object } state - redux state
 *  @returns {Object} - All Salon Itineraries
 *  @Todo Move to dux/entities/itinerariesSelectors.js
 *  @example
 *
 *  getItineraries(state)
 */
export const getItineraries = createSelector([selectEntities], entities => entities?.itineraries);

/**
 *  Selector to get a single Salon itinerary by itineraryId from the Redux State
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getItinerary
 *  @param {Object } state - redux state
 *  @returns {Object} - A single Salon itinerary
 *  @Todo Move to dux/entities/itinerariesSelectors.js
 *  @example
 *
 *  getItinerary(state, {itineraryId})
 */
export const getItinerary = createSelector(
  [getItineraries, getProps],
  (itineraries = {}, { itineraryId }) => itineraries[itineraryId],
);

/**
 *  Selector to check if an invoice has been generated for a salon itinerary
 *  @memberOf Selectors.Entities
 *  @function
 *  @name selectSalonInvoiceGenerated
 *  @param {Object} state - redux state
 *  @param {{ itineraryId: String }} props
 *  @returns {Boolean} - true if an invoice has been generated
 *  @Todo Move to dux/entities/itinerariesSelectors.js
 */
export const selectSalonInvoiceGenerated = createSelector(
  [getItinerary],
  itinerary => !!itinerary?.invoiceId,
);

/**
 *  Selector to get all Pet Ids by Itinerary Id from state.entities.itineraries[itineraryId].pets
 *
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getPetsByItinerary
 *  @param {Object } state - redux state
 *  @param {Object } props - all additional props such as { itineraryId }
 *  @returns {Array}
 *  @Todo Move to dux/entities/petsSelectors.js
 *  @example
 *
 *  getPetsByItinerary(state, {itineraryId})
 */
export const getPetsByItinerary = createSelector([getItinerary], itinerary => itinerary?.pets);

/**
 *  Selector to get A single Pet by a pet Id from state.entities.pets[petId]
 *
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getPetById
 *  @param {Object} state - redux state
 *  @param {Number} petId - Pet Id
 *  @returns {Object}
 *  @Todo Move to dux/entities/petsSelectors.js
 *  @example
 *
 *  getPetById(state, petId)
 */
export const getPetById = (state, petId) => state?.entities?.pets[petId] ?? {};

/**
 *  Selector to get all pet service items from state.entities.petServiceItems
 *
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getPetServiceItems
 *  @param {Object} state - redux state
 *  @returns {Object}
 *  @Todo Move to dux/entities/PetServiceItemsSelectors.js
 *  @example
 *
 *  getPetServiceItems(state)
 */
export const getPetServiceItems = state => state?.entities?.petServiceItems;

/**
 *  Selector to get A single pet service item by petServiceItemId from state.entities.petServiceItems[petServiceItemId]
 *
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getPetServiceItem
 *  @param {Object} state - redux state
 *  @param {Number} petServiceItemId - pet Service Item Id
 *  @returns {Object}
 *  @Todo Move to dux/entities/PetServiceItemsSelectors.js
 *  @example
 *
 *  getPetServiceItem(state, { petServiceItemId })
 */
export const getPetServiceItem = (state, props) =>
  state?.entities?.petServiceItems[props?.petServiceItemId];

/**
 *  Selector to get all associates from state.entities.associates
 *
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getAssociates
 *  @param {Object} state - redux state
 *  @returns {Object}
 *  @Todo Move to dux/entities/associatesSelectors.js
 *  @Todo Remove the use of lodash get() and change to state.entities.associates
 *  @example
 *
 *  getAssociates(state)
 */
export const getAssociates = state => get("entities.associates", state);

/**
 *  Selector to get a single associate by associateId from state.entities.associates[associateId]
 *
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getAssociate
 *  @param {Object} state - redux state
 *  @param {Number} associateId - associate Id
 *  @returns {Object}
 *  @Todo Move to dux/entities/associatesSelectors.js
 *  @Todo Remove the use of lodash get() and change to state.entities.associates[associateId]
 *  @example
 *
 *  getAssociate(state)
 */
export const getAssociate = createSelector(
  [getAssociates, getProps],
  (associates, { associateId }) => associates?.[associateId],
);

/**
 *  Selector to get all employee absences from state.entities.absences
 *
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getAbsences
 *  @param {Object} state - redux state
 *  @returns {Object}
 *  @Todo Move to dux/entities/associatesSelectors.js
 *  @example
 *
 *  getAbsences(state)
 */
export const getAbsences = (state, props) => state?.entities?.absences;

/**
 *  Selector to get all employee absences reason from state.entities.absences
 *
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getAbsenceReasons
 *  @param {Object} state - redux state
 *  @returns {Object}
 *  @Todo Move to dux/entities/associatesSelectors.js
 *  @example
 *
 *  getAbsenceReasons(state)
 */
export const getAbsenceReasons = (state, props) => state.entities.absenceReasons;

export const getAbsence = (state, props) => getAbsences(state)[props.absenceId];

/**
 *  Selector to get Salon addOns from the Redux State
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getAddOns
 *  @param {Object } state - redux state
 *  @returns {Object} - list of Salon Addons
 *  @Todo Move to dux/entities/addOnsSelectors.js
 *  @example
 *
 *  getAddOns(state)
 */
export const getAddOns = (state, props) => state?.entities?.addOns;

/**
 *  Selector to get Salon Enhanced Services from the Redux State
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getEnhancedServices
 *  @param {Object } state - redux state
 *  @returns {Object} - list of Enhanced Services Addons
 *  @Todo Move to dux/entities/enhancedServicesSelectors.js
 *  @example
 *
 *  getEnhancedServices(state)
 */
export const getEnhancedServices = (state, props) => state?.entities?.enhancedServices;

/**
 *  Selector to get isvirtualTrainer info for an associate from the Redux State
 *  @memberOf Selectors.Entities
 *  @function
 *  @name isAssociateVirtualTrainer
 *  @param {Object } state - redux state
 *  @returns {Boolean} - if associate is a virtual trainer
 *  @Todo Move to dux/entities/associatesSelectors.js
 *  @example
 *
 *  isAssociateVirtualTrainer(state)
 */
export const isAssociateVirtualTrainer = createSelector(
  [getAssociate],
  associate => associate && associate.isVirtualTrainer,
);

/**
 *  Selector to get a single Customer by customerKey from the Redux State
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getCustomer
 *  @param {Object } state - redux state
 *  @returns {Object} - A single Customer
 *  @Todo Move to dux/entities/customersSelectors.js
 *  @example
 *
 *  getCustomer(state, {customerKey})
 */
export const getCustomer = (state, props) => {
  return state?.entities?.customers[props?.customerKey];
};

/**
 *  Selector to get Salon Engagements from the Redux State
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getEngagements
 *  @param {Object } state - redux state
 *  @returns {Object} - A Salon Engagements
 *  @Todo Move to dux/entities/SalonEngagementSelectors.js
 *  @Todo Rename to selectSalonEngagements
 *  @example
 *
 *  getEngagements(state)
 */
export const getEngagements = (state, props) => state?.entities?.engagements;

/**
 *  Selector to get a single Salon Engagement from the Redux State
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getEngagement
 *  @param {Object } state - redux state
 *  @returns {Object} - A single Salon Engagement
 *  @Todo Move to dux/entities/SalonEngagementSelectors.js
 *  @Todo Rename to selectSingleSalonEngagement
 *  @example
 *
 *  getEngagement(state, {engagementId})
 */
export const getEngagement = (state, props) => getEngagements(state)[props.engagementId];

export const getSpecials = (state, props) => state.entities.specials;
export const getSpecial = (state, props) => state.entities.specials[props.specialId];
export const getAvailableSpecialIdsByPetServiceItem = (state, props) =>
  get([props.petServiceItemId, "availableSpecials"], state.entities.petServiceItems) || [];
export const getAppliedSpecials = (state, props) =>
  get([props.itineraryId, "appliedSpecials"], state.entities.itineraries) || [];
export const getPriceAdjustmentReasons = (state, props) => state.entities.priceAdjustmentReasons;
export const getPriceAdjustmentReasonValues = (state, props) =>
  state.entities.priceAdjustmentReasons.map(reason => reason.value);
export const getMaxPerBlockOrCheckReductionReasons = (state, props) =>
  state.entities.maxPerBlockOrCheckReductionReasons;
export const getStoreFromSalon = (state, props) =>
  get(["salon", props?.storeNumber], state?.entities);
export const getDayActivities = state => state?.entities?.dayActivities;
export const getSalons = state => state?.entities?.salons;

export const getServicesForPet = (state, props) => state?.entities?.servicesForPet;
export const getService = (state, props) => {
  if (!state.entities.servicesForPet) {
    return null;
  }
  return state.entities.servicesForPet[props.petServiceId];
};

// TODO: move to enums selectors
export const getPetBreed = (state, props) => getBreeds(state)[props.breedId];
export const getBreedsByPet = createSelector([getBreedsList, getPet], (breeds, pet) =>
  breeds.filter(breed => breed.SpeciesId === pet.speciesId),
);

export const getPetBreedName = createSelector([getPetBreed], breed => breed && breed.Description);

export const getPetBreedIsAggressive = createSelector([getBreedsList, getPet], (breeds, pet) => {
  return !!breeds?.find(
    breed =>
      breed?.SpeciesId === pet?.speciesId && breed?.BreedId === pet?.breedId && breed?.IsAggressive,
  );
});

// TODO: refactor to not call selector within combiner
export const getCurrentPetDetails = createSelector(
  [getCurrentPet, (state, props) => state],
  (petId, state) => {
    return getPet(state, { petId });
  },
);

export const getDayTypesAndAbsenceReasons = createSelector([getAbsenceReasons], absenceReasons => {
  const absenceReasonsWithoutLunch = filter(
    absenceReason =>
      absenceReason.value !== absenceReasonsConstants.LUNCH_BREAK &&
      absenceReason.value !== absenceReasonsConstants.DAY_OFF,
    absenceReasons,
  );

  return [
    ...dayTypes,
    ...absenceReasonsWithoutLunch.map(r => ({
      value: r.value,
      label: r.name,
    })),
  ];
});

export const getAddonIdsByService = createSelector(
  [getService],
  service => service?.addOnIds || [],
);

export const getAddonsByService = createSelector([getAddOns, getService], (addons, service) => {
  const mostPopular = [];
  const mainAddOns = [];

  if (service?.addOnIds) {
    service.addOnIds.forEach(addonId => {
      const addon = addons[addonId];
      if (addon) {
        if (mostPopular.length < 5) {
          mostPopular.push(addon);
        } else {
          mainAddOns.push(addon);
        }
      }
    });
  }

  return {
    mostPopular,
    mainAddOns,
  };
});

export const getAssociateFormData = createSelector([getAssociate], associate => ({
  associateId: normalizeFormInput(associate, "associateId"),
  firstName: normalizeFormInput(associate, "firstName"),
  lastName: normalizeFormInput(associate, "lastName"),
  associateGroup: normalizeFormInput(associate, "associateGroup"),
  phoneNumber: normalizeFormInput(associate, "phoneNumber"),
  commisionPercentage: normalizeFormInput(associate, "commisionPercentage", "99999"),
  weightRestriction: normalizeFormInput(associate, "weightLbsRestriction"),
  maxPerBlock1: normalizeFormInput(associate, "maxPerBlock1"),
  maxPerBlock2: normalizeFormInput(associate, "maxPerBlock2"),
  preferredName: normalizeFormInput(associate, "preferredName"),
  maxCheckIn: normalizeFormInput(associate, "maxCheckIn"),
  associateBio: normalizeFormInput(associate, "associateBio"),
}));

// TODO: refactor to use createSelector
export const getServicesIdsByPet = (state, props) => {
  const pet = getPet(state, props);
  return pet ? pet.petServiceIds || [] : [];
};

export const getStoreInformation = (state, props) => state?.entities?.salon[props?.storeNumber];

export const getStoreRegion = createSelector([getStoreInformation], store => {
  return store?.CountryNameAbbreviation;
});

export const getStoreCountry = createSelector([getStoreInformation], store => {
  return store?.CountryNameAbbreviation === "US" ? "USA" : store?.CountryNameAbbreviation;
});

export const getServicesByPet = createSelector(
  [getServicesIdsByPet, getServicesForPet],
  (servicesIdsByPet, services) => {
    if (isEmpty(services)) {
      return [];
    }
    return servicesIdsByPet.map(serviceId => services[serviceId] || {});
  },
);

export const getCommonServicesByPet = createSelector([getServicesByPet], servicesByPet => {
  if (isEmpty(servicesByPet)) {
    return [];
  }
  return servicesByPet.filter(service => !service.isStandalone);
});

export const getStandaloneServiceByPet = createSelector([getServicesByPet], servicesByPet => {
  if (isEmpty(servicesByPet)) {
    return {};
  }
  return servicesByPet.find(service => service.isStandalone);
});

export const handleCustomerAPIError = (...args) => {
  const errors = compact(args);

  if (!errors.length) {
    return {};
  }

  const result = errors.reduce((accumulator, error) => {
    const errorMessage = {};
    const message = getApiError(error);

    if (isDuplicateEmail(message)) {
      errorMessage.email = DUPLICATE_EMAIL_USER_FRIENDLY_ERROR_MESSAGE;
    } else if (isDuplicatePhone(message)) {
      errorMessage.phone = DUPLICATE_PHONE_USER_FRIENDLY_ERROR_MESSAGE;
    } else {
      errorMessage.other = message;
    }

    return { ...accumulator, ...errorMessage };
  }, {});

  return result;
};

export const processCreateCustomerError = createSelector(
  [getCreateCustomerError],
  handleCustomerAPIError,
);

export const processUpdateCustomerError = createSelector(
  [
    getUpdateCustomerError,
    getCreateCustomerPhoneError,
    getUpdateCustomerPhoneError,
    getCreateCustomerEmailError,
    getUpdateCustomerEmailError,
  ],
  handleCustomerAPIError,
);

export const getAssociateByPetServiceId = createSelector(
  [getPetServiceItem, getAssociates],
  (petServiceItem, associates) => associates[petServiceItem.associate],
);

export const getPetByPetServiceItemId = createSelector(
  [getPetServiceItem, getPets],
  (petServiceItem, pets) => {
    if (!petServiceItem) {
      return null;
    }

    return pets[petServiceItem.pet];
  },
);

export const getPetIdByPetServiceItemId = createSelector([getPetServiceItem], petServiceItem => {
  if (!petServiceItem) {
    return null;
  }

  return petServiceItem.pet;
});

export const getCustomerByPetServiceItemId = createSelector(
  [getPetServiceItem, getCustomers, getPets],
  (petServiceItem, customers, pets) =>
    getCustomerByPetServiceItem({
      petServiceItem,
      customers,
      pets,
    }),
);

export const getAppointmentsByCustomer = createSelector(
  [getCustomer, getPetServiceItems],
  (customer, petServiceItems) =>
    customer
      ? Object.values(petServiceItems)
          .filter(petServiceItem => {
            return (
              petServiceItem.customer === customer.customerKey &&
              customer.pets.includes(petServiceItem.pet)
            );
          })
          .sort((previous, current) =>
            getTimestampDiff(previous.startDateTime, current.startDateTime),
          )
          .map(petServiceItem => petServiceItem.petServiceItemId)
      : [],
);

export const getAppointmentsByCustomerAndFilterStandalonesFactory = includeStandalones => {
  return createSelector([getCustomer, getPetServiceItems], (customer, petServiceItems) =>
    customer
      ? Object.values(petServiceItems)
          .filter(petServiceItem => {
            return (
              petServiceItem.customer === customer.customerKey &&
              customer.pets.includes(petServiceItem.pet) &&
              petServiceItem.isStandalone === includeStandalones
            );
          })
          .sort((previous, current) => {
            const previousStartDateTime = convertDateToTimestamp(previous.startDateTime);
            const currentStartDateTime = convertDateToTimestamp(current.startDateTime);

            return moment.utc(currentStartDateTime).diff(moment.utc(previousStartDateTime));
          })
          .map(petServiceItem => petServiceItem.petServiceItemId)
      : [],
  );
};

export const getAppointmentsByCustomerBGM = getAppointmentsByCustomerAndFilterStandalonesFactory(
  false,
);

export const getStandaloneAppointmentsByCustomer = getAppointmentsByCustomerAndFilterStandalonesFactory(
  true,
);

// TODO: move to it's own dux
export const getFilterAppointmentsByEngagementType = state =>
  state.filterAppointmentsByEngagementType;

export const getPastOrFutureAppointmentsByCustomerFactory = (isBeforeOrAfter, selector) => {
  return createSelector(
    [selector, getPetServiceItems, getEngagements, getFilterAppointmentsByEngagementType],
    (appointmentsByCustomer, petServiceItems, engagements, engagementFilters) => {
      const filterValues = Object.values(engagementFilters)
        .filter(item => item.isPressed)
        .map(item => item.filterValue);

      return appointmentsByCustomer.filter(appointmentId => {
        const appointment = petServiceItems[appointmentId];
        const engagementType = engagements[appointment?.engagement]?.engagementType;

        // filter the list by engagement type, but first check that there are any
        // filtering values. this handles the "all" scenerio or "no filters" and
        // we still want to display the entire list
        if (filterValues.length && !filterValues.includes(engagementType)) {
          return;
        }

        return (
          moment(appointment.startDateTime)[isBeforeOrAfter](moment()) &&
          appointment.status !== APPOINTMENT_STATUS.PENDING
        );
      });
    },
  );
};

export const getPastAppointmentsByCustomer = getPastOrFutureAppointmentsByCustomerFactory(
  "isBefore",
  getAppointmentsByCustomer,
);

export const getFutureAppointmentsByCustomer = getPastOrFutureAppointmentsByCustomerFactory(
  "isAfter",
  getAppointmentsByCustomer,
);

export const getPastAppointmentsByCustomerBGM = getPastOrFutureAppointmentsByCustomerFactory(
  "isBefore",
  getAppointmentsByCustomerBGM,
);

export const getFutureAppointmentsByCustomerBGM = getPastOrFutureAppointmentsByCustomerFactory(
  "isAfter",
  getAppointmentsByCustomerBGM,
);

export const getPastStandaloneAppointmentsByCustomer = getPastOrFutureAppointmentsByCustomerFactory(
  "isBefore",
  getStandaloneAppointmentsByCustomer,
);

export const getFutureStandaloneAppointmentsByCustomer = getPastOrFutureAppointmentsByCustomerFactory(
  "isAfter",
  getStandaloneAppointmentsByCustomer,
);

export const getIsCustomerAppointmentListEmptyFactory = selector => {
  return createSelector([selector], appointments => isEmpty(appointments));
};

export const getIsCustomerAppointmentListEmpty = getIsCustomerAppointmentListEmptyFactory(
  getAppointmentsByCustomer,
);

export const getIsCustomerAppointmentListEmptyBGM = getIsCustomerAppointmentListEmptyFactory(
  getAppointmentsByCustomerBGM,
);

/**
 * Re-select Selector
 * @returns {Array} An Array of Pet Service Item Ids, e.g. [123, 321, ...]
 */
export const getAppointmentsByDate = createSelector(
  [getSalonHours, getSelectedDate, getProps, getPetServiceItems, getStoreNumber],
  (salonHours, selectedDate, props, petServiceItems, storeNumber) => {
    selectedDate = props.selectedDate || selectedDate;
    if (
      isEmpty(salonHours) ||
      !salonHours[selectedDate] ||
      !salonHours[selectedDate].petServiceItems
    ) {
      return [];
    }
    return salonHours[selectedDate].petServiceItems.filter(petServiceItemId => {
      const petServiceItem = petServiceItems[petServiceItemId];
      return filterValidDailyAppointments({ petServiceItem, storeNumber, selectedDate });
    });
  },
);

// NOTE: return an empty array for associates that don't have appointments. When we will have shifts - won't be necessary
export const getAppointmentsByAssociateAndDate = createSelector(
  [getAppointmentsByDate, getPetServiceItems, getAssociates],
  (appointmentsByDate, petServiceItems, associates = {}) => {
    const appointmentsByAssociate = {};
    Object.keys(associates).forEach(associateId => {
      appointmentsByAssociate[associateId] = [];
    });
    appointmentsByDate.forEach(appointmentId => {
      const associateId =
        petServiceItems[appointmentId] && petServiceItems[appointmentId].associate;
      if (associateId) {
        const currentAssociateAppointments = appointmentsByAssociate[associateId] || [];
        appointmentsByAssociate[associateId] = [...currentAssociateAppointments, appointmentId];
      }
    });
    return appointmentsByAssociate;
  },
);

/** ----------------------------------------------------------------------- **\
    //TODO: Move to the upcomingAndHereSelectors file.

    All selectors for the upcoming and here features, both for mobile and
    Web should be moved to the upcomingAndHereSelectors.js file. Though
    we need to be mindful of circular dependency.

    Given that a selector might not be available at module evaluation (when
    the selector is created) should not matter, given that the selector is not
    invoked until later, by which point the reference should have resolved.
    For this reason, circular dependencies bt/ functions are generally not an
    issue, assuming that the functions are not invoked before all modules have been evaluated.

    However, b/c webpack (and all other bundlers) passes arguments to createSelector
    as values, not as bindings, they do not end up pointing to the correct selector
    function after the dependent module is evaluated, and are still undefined
    when the selector is invoked.

    es6 module bindings are supposed to handle this case correctly, but, no bundler
    build tool gets this case right. So, until one does, I guess the only
    workaround is to ensure that selectors don't contain circular dependencies
    (even if they arguably should be allowed to do so)

    more on this issue: https://github.com/reduxjs/reselect/issues/169:
\ ----------------------------------------------------------------------- * */

/**
 * Re-select Selector that returns an array of sorted Ids for appointments with a 'booked or Confirmed status
 * @returns {Array} An Array of confirmed or Booked appointment ids.
 */
export const getUpcomingAppointments = createSelector(
  [
    getAppointmentsByDate,
    getPetServiceItems,
    getAssociates,
    getPets,
    getCustomers,
    getEngagements,
    getUpcomingSearchText,
    getUpcomingBoardedGuestChecked,
  ],
  (
    appointmentsByDate,
    petServiceItems,
    associates,
    pets,
    customers,
    engagements,
    upcomingSearchText,
    upcomingBoardedGuestChecked,
  ) => {
    const appointmentStatus = appointmentsByDate.filter(
      petServiceItemId =>
        petServiceItems[petServiceItemId].status === APPOINTMENT_STATUS.CONFIRMED ||
        petServiceItems[petServiceItemId].status === APPOINTMENT_STATUS.BOOKED,
    );

    const searchfilter = appointmentStatus.filter(petServiceItemId =>
      filterUpcomingAndHereBySearchText({
        customers,
        pets,
        associates,
        petServiceItemId,
        petServiceItems,
        searchText: upcomingSearchText,
      }),
    );

    const boarded = searchfilter.filter(petServiceItemId =>
      filterIsBoarded({
        isChecked: upcomingBoardedGuestChecked,
        petServiceItems,
        engagements,
        petServiceItemId,
      }),
    );

    const sort = boarded.sort((a, b) =>
      moment
        .utc(petServiceItems[a].startDateTime)
        .diff(moment.utc(petServiceItems[b].startDateTime)),
    );

    return sort;
  },
);

/**
 * Re-select Selector that returns an array of sorted Ids for appointments with a 'Service Completed',
 * 'Checked-Out' or 'Checked-In' status
 * @returns {Array} An Array of completed appointment ids.
 */
export const getHereAppointments = createSelector(
  [
    getAppointmentsByDate,
    getPetServiceItems,
    getHereOrCompletedFilterApplied,
    getAssociates,
    getPets,
    getCustomers,
    getEngagements,
    getHereSearchText,
    getHereBoardedGuestChecked,
  ],
  (
    appointmentsByDate,
    petServiceItems,
    filterApplied,
    associates,
    pets,
    customers,
    engagements,
    hereSearchText,
    hereBoardedGuestChecked,
  ) =>
    appointmentsByDate
      .filter(petServiceItemId =>
        filterApplied === "Completed"
          ? petServiceItems[petServiceItemId].status === APPOINTMENT_STATUS.CHECKED_OUT
          : petServiceItems[petServiceItemId].status === APPOINTMENT_STATUS.SERVICE_COMPLETED ||
            petServiceItems[petServiceItemId].status === APPOINTMENT_STATUS.CHECKED_IN,
      )
      .filter(petServiceItemId =>
        filterUpcomingAndHereBySearchText({
          customers,
          pets,
          associates,
          petServiceItemId,
          petServiceItems,
          searchText: hereSearchText,
        }),
      )
      .filter(petServiceItemId =>
        filterIsBoarded({
          isChecked: hereBoardedGuestChecked,
          petServiceItems,
          engagements,
          petServiceItemId,
        }),
      )
      .sort((a, b) => {
        if (
          petServiceItems[a].status === APPOINTMENT_STATUS.SERVICE_COMPLETED &&
          petServiceItems[b].status !== APPOINTMENT_STATUS.SERVICE_COMPLETED
        ) {
          return -1;
        }
        if (
          petServiceItems[a].status !== APPOINTMENT_STATUS.SERVICE_COMPLETED &&
          petServiceItems[b].status === APPOINTMENT_STATUS.SERVICE_COMPLETED
        ) {
          return 1;
        }

        return moment
          .utc(petServiceItems[a].endDateTime)
          .diff(moment.utc(petServiceItems[b].endDateTime));
      }),
);

export const getAppointmentsByEngagementTypeFactory = (appointmentSelector, engagementType) =>
  createSelector([appointmentSelector, getPetServiceItems], (appointmentIds, petServiceItems) => {
    return appointmentIds.filter(id => petServiceItems[id].engagementType === engagementType);
  });

export const getUpcomingAppointmentsForSalon = getAppointmentsByEngagementTypeFactory(
  getUpcomingAppointments,
  engagementTypes.GROOMING,
);

export const getHereAppointmentsForSalon = getAppointmentsByEngagementTypeFactory(
  getHereAppointments,
  engagementTypes.GROOMING,
);

/**
 * Aggregate all needed properties for each upcoming and here item into one single object
 * @param {Object} petServiceItems - All appointments
 * @param {Number} appointmentId - a single appointment ID
 * @param {Object} pets - All pets
 * @param {Object} customers - All customers
 * @param {Object} associates - All Associates
 *
 * @returns {Object} A single aggregated appointment object.
 */
export function aggregateAppointmentData(
  petServiceItems,
  appointmentId,
  pets,
  customers,
  associates,
  breeds,
) {
  const customerId = petServiceItems[appointmentId].customer;
  const petId = petServiceItems[appointmentId].pet;
  const associateId = petServiceItems[appointmentId].associate;

  if (!pets[petId]) {
    return {};
  }

  const customer =
    customers[customerId] || pets[petId].owners.find(owner => owner.customerKey === customerId);
  const { breedId } = pets[petId];
  const serviceItem = {
    ...petServiceItems[appointmentId],
    petFirstName: pets[petId].petName,
    LastName: customer && customer.lastName,
    customerFirstName: customer && customer.firstName,
    breedName: breedId && breeds[breedId] && breeds[breedId].Description,
    associateName: associates[associateId] && associates[associateId].associateName,
  };

  return serviceItem;
}

/**
 * A composed Re-select Selector that returns an Array of appointment Objects
 * for upcoming and here. This selector is mainly intended for the mobile RN
 * application.
 * @returns {Array} An Array of appointment Objects.
 */
export const getUpcomingHereAppointmentsRN = createSelector(
  [
    getUpcomingAppointments,
    getHereAppointments,
    getPetServiceItems,
    getCustomers,
    getPets,
    getAssociates,
    getBreeds,
  ],
  (upComingIds, hereIds, petServiceItems, customers, pets, associates, breeds) => {
    const upComingAppointments = upComingIds.map(appointmentId =>
      aggregateAppointmentData(petServiceItems, appointmentId, pets, customers, associates, breeds),
    );

    const HereAppointments = hereIds.map(appointmentId =>
      aggregateAppointmentData(petServiceItems, appointmentId, pets, customers, associates, breeds),
    );

    return {
      upcomming: upComingAppointments,
      here: HereAppointments,
    };
  },
);
/** ----------------------------------------------------------------------- * */

export const getPetsNamesAndIdsByCustomer = createSelector(
  [getPets, getCustomer],
  (pets, customer) =>
    customer && customer.pets
      ? customer.pets.map(petId => ({ petId, petName: pets[petId].petName }))
      : [],
);

export const getPetsNamesAndIdsAndActiveByCustomer = createSelector(
  [getPets, getCustomer],
  (pets, customer) =>
    customer && customer.pets
      ? customer.pets
          .map(petId => ({ petId, petName: pets[petId].petName, isActive: pets[petId].isActive }))
          .sort((x, y) => ((x.isActive === y).isActive ? 0 : x.isActive ? -1 : 1))
      : [],
);

// @param customerKey
export const getActivePetsForTabsList = createSelector(
  [getPetsNamesAndIdsAndActiveByCustomer],
  pets => pets?.filter(pet => !!pet.isActive),
);

export const getPetFormData = createSelector([getPet], pet => ({
  petName: normalizeFormInput(pet, "petName"),
  weight: normalizeFormInput(pet, "weight"),
  breedId: normalizeFormInput(pet, "breedId"),
  genderId: normalizeFormInput(pet, "genderId"),
  speciesId: normalizeFormInput(pet, "speciesId"),
  colorId: normalizeFormInput(pet, "colorId"),
  isActive: normalizeFormInput(pet, "isActive", true),
  birthDate: pet && pet.birthDate ? pet.birthDate.split("T")[0] : "",
  age: pet && pet.birthDate ? calculateAge(pet.birthDate) : "",
  isMixedBreed: normalizeFormInput(pet, "isMixedBreed", false),
  isSpayedNeutered: normalizeFormInput(pet, "isSpayedNeutered", false),
  markings: normalizeFormInput(pet, "petMarkings"),
}));

export const getCustomerFirstName = createSelector(
  [getCustomer],
  customer => customer && `${customer.firstName}`.replace(/\s+/g, " ").trim(),
);

export const getCustomerFullName = createSelector(
  [getCustomer],
  customer => customer && `${customer.firstName} ${customer.lastName}`.replace(/\s+/g, " ").trim(),
);

export const getCustomerPhone = createSelector([getCustomer], customer => {
  if (customer) {
    const primaryPhone = customer.phones.find(phone => phone.isPrimary);
    return formatPhoneNumberNANP(primaryPhone && primaryPhone.phoneNumber);
  }
});

export const getCustomerEmail = createSelector([getCustomer], customer => {
  if (customer) {
    const primaryEmail = customer.emails.find(email => email.isPrimary);
    return primaryEmail && primaryEmail.email;
  }
});

export const getPetFullName = createSelector(
  [getCustomer, getPet],
  (customer, pet) => customer && pet && `${pet.petName} ${customer.lastName}`,
);

export const getCustomerLastName = createSelector(
  [getCustomer],
  customer => customer && customer.lastName,
);

export const getPetPhoto = createSelector(
  [getPet],
  pet => getUnknownPetImage(pet),
  // pet => PuppyImage
);

export const getIsPetActive = createSelector([getPet], pet => pet && pet.isActive);

export const getCustomerStatuses = createSelector(
  [getCustomer],
  customer => customer && customer.statuses,
);

export const getIsCustomerActive = createSelector(
  [getCustomerStatuses],
  statuses =>
    statuses && statuses.some(status => status.statusId === ACTIVE_IN_PRISM_ID && status.isActive),
);

export const getPetParentAlertsFormData = createSelector([getCustomer], customer => {
  return parseAlertData(customer);
});

export const getPetAlertsFormData = createSelector([getPet], pet => ({
  isBookingAlert: pet ? Boolean(pet.groomingBookingAlert) : false,
  bookingAlertReason: pet ? pet.groomingBookingAlert || "" : "",
  isCheckInAlert: pet ? Boolean(pet.groomingCheckInAlert) : false,
  checkInAlertReason: pet ? pet.groomingCheckInAlert || "" : "",
  isCheckOutAlert: pet ? Boolean(pet.groomingCheckOutAlert) : false,
  checkOutAlertReason: pet ? pet.groomingCheckOutAlert || "" : "",
  isServiceCardAlert: pet ? Boolean(pet.groomingServiceCardAlert) : false,
  serviceCardAlertReason: pet ? pet.groomingServiceCardAlert || "" : "",
}));

export const getStoreData = createSelector([getStoreInformation], salon => {
  const salonInfo = salon && salon?.StoreServices?.find(services => services?.ServiceId === 3); // Find store services hours for grooming (ID# 3)
  const salonTimes = salonInfo && salonInfo?.StoreServiceHoursForDateList;

  return {
    storeName: salon ? salon.Name : "",
    storeNumber: salon ? salon.StoreNumber : "",
    storeAddress: salon
      ? `${salon.StreetLine1} ${salon.City}, ${salon.StateProvinceAbbreviation} ${salon.ZipPostalCode}`
      : "",
    storePhone:
      salon && salon.PhoneNumber
        ? salon.PhoneNumber.replace(
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            "($1) $2-$3",
          )
        : "",
    salonHours: salonTimes
      ? normalizeArrayByProperty(
          salonTimes.map(day => ({
            dayOfWeek: day ? day.DayOfWeek : "",
            openTime: day ? getUSTimeStringByDate(moment(day.OpenTime, "HH:mm:ss"), true) : "",
            closeTime: day ? getUSTimeStringByDate(moment(day.CloseTime, "HH:mm:ss"), true) : "",
          })),
          "dayOfWeek",
        )
      : [],
  };
});

export const getStoreTimeZoneOffset = createSelector([getStoreInformation], store => {
  const offSet = get(["TimeZone", "RawOffset"], store);
  const daylightSavingsOffSet = get(["TimeZone", "DstOffset"], store);

  const convertedOffSet = offSet / 3600 + daylightSavingsOffSet / 3600; // Convert from seconds to hours and account for daylight savings.

  if (!offSet) {
    return moment().utcOffset() / 60; // Return default offset (converted from minutes to hours) if missing API offset.
  }

  return convertedOffSet;
});

/**
 *  Selector to get the rawOffset for a given store
 *  @memberOf Selectors.Entities
 *  @function
 *  @name getStoreTimeZoneRawOffset
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {Number} props.storeNumber
 *  @returns {Number|undefined}
 *  @example getStoreTimeZoneRawOffset(state, { storeNumber })
 */
export const getStoreTimeZoneRawOffset = createSelector(
  [getStoreInformation],
  storeInfo => storeInfo?.TimeZone?.RawOffset,
);

export const getCustomerPrimaryEmailId = createSelector([getCustomer], customer => {
  const primaryEmail = customer && customer.emails && customer.emails.find(Boolean);
  return get(["emailAddressId"], primaryEmail);
});

export const getSalonTimes = compose(
  get("StoreServiceHoursForDateList"),
  find(matchesProperty("ServiceId", serviceTypeIds.GROOMING)),
  get("StoreServices"),
);

export const getPhonesByCustomer = createSelector(
  [getCustomer],
  customer => customer && customer.phones,
);

export const getCustomerPreferredPhoneByType = createSelector([getPhonesByCustomer], phones => {
  const preferredPhone = phones && phones.find(getIsPhonePreferred);
  return preferredPhone ? preferredPhone.phoneType : "";
});

export const getEmailsByCustomer = createSelector(
  [getCustomer],
  customer => customer && customer.emails,
);

export const getAbsencesByDateAndAssociate = createSelector(
  [getSalonHours, getAbsences, getSelectedDate, getProps],
  (hours = {}, absences, selectedDate, props) => {
    selectedDate = props.selectedDate || selectedDate;
    if (!hours[selectedDate] || !hours[selectedDate].absences) {
      return [];
    }

    return hours[selectedDate].absences
      .filter(
        absenceId =>
          absences[absenceId] && Number(absences[absenceId].associateId) === props.associateId,
      ) // TEMP CONVERSION
      .map(absenceId => absences[absenceId]);
  },
);

export const getBreakByDateAndAssociate = createSelector(
  [getAbsencesByDateAndAssociate, getSelectedDate, getProps],
  (absences, selectedDate, props) => {
    selectedDate = props.selectedDate || selectedDate;
    const currentBreak = find({ absenceReason: absenceTypes.BREAK }, absences);
    const defaultBreakDate = getISODateWithTime(selectedDate, DEFAULT_BREAK_HOUR);

    if (!currentBreak) {
      return;
    }

    return {
      startDate: currentBreak.absenceStartDateTime,
      endDate: currentBreak.absenceEndDateTime,
    };
  },
);

export const getStateSliceForAvailableAppointments = state => ({
  entities: {
    absences: getAbsences(state),
    associates: getAssociates(state),
    petServiceItems: getPetServiceItems(state),
  },
  salonHours: getSalonHours(state),
  selectedDate: getSelectedDate(state),
  persistent: {
    currentStore: getStoreNumber(state),
  },
});

const isNotPetParent = customerKey => owner => Number(owner.customerKey) !== Number(customerKey);

export const getPetCoOwners = createSelector(
  [getPet, getProps],
  (pet, { customerKey }) =>
    (pet &&
      pet.owners &&
      pet.owners.filter(owner => get("isActive", owner) && isNotPetParent(customerKey)(owner))) ||
    [],
);

export const getPetServiceItemsByItinerary = createSelector(
  [getPetServiceItems, getItineraries, (state, props) => props],
  (petServiceItems, itineraries, { itineraryId }) =>
    !isEmpty(itineraries) && !isEmpty(itineraries[itineraryId])
      ? itineraries[itineraryId].petServiceItems.map(
          petServiceItemsId => petServiceItems[petServiceItemsId],
        )
      : [],
);

export const getPetServiceItemsByItineraryWithPetActiveStatus = createSelector(
  [getPetServiceItemsByItinerary, getState],
  (petServiceItems, state) =>
    petServiceItems.map(petServiceItem => {
      const pet = getPetById(state, petServiceItem.pet);
      return { ...petServiceItem, isPetActive: pet?.isActive };
    }),
);

export const getPetsByIds = createSelector([getPets, getProps], (allPets, props) => {
  const { petIds } = props;

  if (!petIds) {
    return [];
  }

  const result = petIds.map(petId => {
    if (allPets[petId]) {
      return allPets[petId];
    }
    return null;
  });

  return result;
});

export const getShouldShowServiceCompleteForAll = createSelector(
  [getItinerary, getPetServiceItems],
  (itinerary, petServiceItemsInStore) => {
    if (get("pets.length", itinerary) === 1) {
      return false;
    }

    const petServiceItems = getOr([], "petServiceItems", itinerary);
    const statuses = petServiceItems.map(petServiceItemId => {
      const petServiceItem = petServiceItemsInStore[petServiceItemId];

      if (!petServiceItem) {
        return;
      }

      return petServiceItem.status;
    });

    return (
      statuses.every(
        status =>
          status === APPOINTMENT_STATUS.CHECKED_IN ||
          status === APPOINTMENT_STATUS.CHECKED_OUT ||
          status === APPOINTMENT_STATUS.SERVICE_COMPLETED,
      ) &&
      !statuses.every(
        status =>
          status === APPOINTMENT_STATUS.SERVICE_COMPLETED ||
          status === APPOINTMENT_STATUS.CHECKED_OUT,
      )
    );
  },
);

export const getPetIdByEngagementId = createSelector(
  [getEngagement],
  engagement => engagement?.pet,
);

export const getNumberOfActivePets = createSelector(
  [getCustomer, getPets],
  (customer, pets) =>
    (customer && customer.pets && customer.pets.filter(petId => pets[petId].isActive).length) || 0,
);

export const getAreAllPetServiceItemsCancelled = createSelector(
  [getPetServiceItemsByItinerary],
  petServiceItems => petServiceItems.every(psi => psi.status === APPOINTMENT_STATUS.CANCELED),
);

export const getIsOnePetServiceItemCancelled = createSelector(
  [getPetServiceItemsByItinerary],
  petServiceItems => petServiceItems.some(psi => psi.status === APPOINTMENT_STATUS.CANCELED),
);

export const getDoServiceItemsMatch = createSelector(
  [getPetServiceItemsByItinerary],
  petServiceItems => petServiceItems.every(psi => psi.status === petServiceItems[0].status),
);

// If service doesn't have a pet service name,
// make sure to pull the service details from the pet service
// item instead.
export const getServiceDetailsForCart = createSelector(
  [getService, getPetServiceItem],
  (service, petServiceItem) => {
    const serviceName = get("name", service);
    if (!serviceName) {
      // Service does not have full details
      return {
        ...petServiceItem,
        price: get("bookedPrice", petServiceItem),
        name: get("petServiceName", petServiceItem),
      };
    }
    return service;
  },
);

export function countSpeciesByAppointmentType(pets, services, appointmentType) {
  const petList = appointmentType.map(id => pets[services[id].pet]);
  const emptyCounts = { 1: [], 2: [] };
  const species = ["dog", "cat"];

  // while the data is loading, return empty counts for each species
  if (petList.includes(undefined)) return { dog: 0, cat: 0 };

  // group the species together so they can be counted,
  // provide empty arrays for a missing species,
  // and rename the keys to be "dog" and "cat"
  const petGroups = groupBy(petList, "speciesId");
  const defaults = { ...emptyCounts, ...petGroups };
  const petCounts = Object.keys(defaults).map(key => ({ [key]: defaults[key].length }));
  const petCountsObject = Object.assign({}, ...petCounts);

  return arraysToObject(species, Object.values(petCountsObject));
}

/**
 * Calculates the count of each species (dog or cat) for each appointment type (upcoming and here)
 */
export const getSpeciesCountsByAppointmentType = createSelector(
  [getUpcomingAppointments, getHereAppointments, getPetServiceItems, getPets],
  (upcoming, here, services, pets) => ({
    upcoming: countSpeciesByAppointmentType(pets, services, upcoming),
    here: countSpeciesByAppointmentType(pets, services, here),
  }),
);

export const getStoreTimeZone = createSelector(
  [getStoreFromSalon],
  salon => salon?.TimeZone?.TimeZoneId,
);

export const getGroomingSkills = createSelector([getAssociate, getPet], (associate, petDetails) => {
  const speciesType = petDetails?.speciesId === 1 ? "Dog" : "Cat";
  const { skills: { grooming = [] } = {} } = associate;
  return grooming.some(item => item === speciesType);
});
