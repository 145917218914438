import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TextPassage } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { buildEngagementsForPrimaryServicePatch } from "@/core/utils/hotelEngagementUtils/buildEngagementsForPrimaryServicePatch";
import { HotelCheckInOutEditIconContainer } from "../hotelCheckInOutDateTimeCart/hotelCheckInOutEditIcon";
import { patchHotelPrimaryServiceChange } from "../hotelPrimaryServiceChange/hotelPrimaryServiceChangeActions";
import {
  getPetHotelEngagements,
  selectAllPetServicesMatch,
  selectCurrentHotelPetService,
} from "../hotelEngagements/hotelEngagementSelectors";
import { selectIsReservationDisabled } from "../hotelItinerary/hotelItinerarySelectors";
import { HotelPrimaryServiceDropdown } from "./PrimaryServiceDropdown";
import { selectIsHotelUpdateServiceOpen } from "../toggleAccordion/toggleAccordionSelectors";
import { toggleHotelUpdateService } from "../toggleAccordion/toggleAccordionActions";

/**
 * React view component that is used to update a primary service select field
 * @summary Used in hotel check in & out
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name PrimaryServiceSelectionComponent
 * @param {Object} props
 * @param {String} props.componentId
 * @param {Boolean} props.isHidden
 * @param {String} props.serviceName
 * @param {Number} props.petServiceId
 * @param {Boolean} props.isPencilDisabled
 * @param {Boolean} props.isLoadingServices
 * @param {Function} props.setPetService
 * @returns {JSX.Element || null}
 */
export const PrimaryServiceSelectionComponent = ({
  componentId,
  isHidden,
  serviceName,
  petServiceId,
  isPencilDisabled,
  isEditing,
  setIsEditing = () => {},
  setPetService = () => {},
}) => {
  // If pet service can no longer be changed for all days or if selected value changes then close section
  useEffect(() => {
    if (isEditing) setIsEditing(false);
  }, [petServiceId, isPencilDisabled]);

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutCluster>
        <TextPassage size="lg">Room Type: {isEditing ? "" : serviceName}</TextPassage>
        <HotelPrimaryServiceDropdown isHidden={!isEditing} onChange={setPetService} />
        <HotelCheckInOutEditIconContainer
          onClick={() => setIsEditing(!isEditing)}
          isDisabled={isPencilDisabled}
        />
      </LayoutCluster>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the primary service select field
 * @see {@link Views.Hotel.Itinerary.PrimaryServiceSelectionComponent}
 * @summary Located on the hotel check-in & check-out pages
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelCheckInOutServiceSelect
 * @returns {JSX.Element|null}
 * @example <HotelCheckInOutServiceSelect  />
 */
export const HotelCheckInOutServiceSelect = connect(
  state => {
    const petId = getCurrentPet(state);
    const service = selectCurrentHotelPetService(state, { petId });
    const allEngagementServiceIdsMatch = selectAllPetServicesMatch(state, { petId });
    const isReservationDisabled = selectIsReservationDisabled(state);

    return {
      componentId: "HotelCheckInOutServiceSelect",
      serviceName: service?.petServiceName,
      petServiceId: service?.petServiceId,
      petId,
      petEngagements: getPetHotelEngagements(state, { petId }),
      isPencilDisabled: isReservationDisabled || !allEngagementServiceIdsMatch,
      isEditing: selectIsHotelUpdateServiceOpen(state),
    };
  },
  dispatch => {
    return {
      changePrimaryService: data => dispatch(patchHotelPrimaryServiceChange({ data })),
      setIsEditing: isEditing => dispatch(toggleHotelUpdateService(isEditing)),
    };
  },
  (stateProps, dispatchProps) => {
    const {
      componentId,
      serviceName,
      petServiceId,
      petId,
      petEngagements,
      isPencilDisabled,
      isEditing,
    } = stateProps;
    const { changePrimaryService, setIsEditing } = dispatchProps;

    return {
      componentId,
      serviceName,
      petServiceId,
      isPencilDisabled,
      isEditing,
      setIsEditing,
      setPetService: service => {
        if (!service) return;

        // Construct engagements for current pet
        const engagements = buildEngagementsForPrimaryServicePatch({
          petEngagements,
          petServiceId: service?.petServiceId,
          hostPetId: service?.hostPetId,
          roomTypeBucketId: service?.roomTypeBucketId,
          roomType: service?.roomTypeId,
          applyToEngagements: petEngagements?.map(({ engagementId }) => engagementId),
        });

        changePrimaryService({ pets: [{ petKey: petId, engagements }] });
      },
    };
  },
)(PrimaryServiceSelectionComponent);
