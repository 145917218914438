import React from "react";
import styled from "styled-components";
import { color, font } from "../../../../common/styles/theme";

export default function QuickViewPetCard(props) {
  const SectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `;

  const InfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 53px;
  `;

  const InfoListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  `;

  const InfoList = styled.div`
    font-family: ${font.mainFamily};
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.07;
    letter-spacing: normal;
    color: ${color.black0};
  `;

  const PetList = styled.div`
    width: 100%;
  `;

  /** ----------------------------------------------------------------------- * */
  // REPLACE WITH COMMON COMPONENT
  const TempWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  `;
  const TempTitle = styled.div`
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #000000;
  `;

  const TempImage = styled.img`
    width: 44px;
    height: 44px;
    border-radius: 7px;
  `;
  /** ----------------------------------------------------------------------- * */

  // Component JSX
  return (
    <SectionWrapper>
      {props.petList.map((pet, index) => (
        <PetList key={pet.petId}>
          <TempWrapper>
            <TempTitle>{pet.petName}</TempTitle>
            <TempImage src="https://via.placeholder.com/44x44" />
          </TempWrapper>

          <InfoWrapper>
            <InfoListWrapper>
              <InfoList>{pet.petName}</InfoList>
              <InfoList>NoAPIvalue</InfoList>
            </InfoListWrapper>

            <InfoListWrapper>
              <InfoList>NoAPIValue</InfoList>
              <InfoList>NoAPIvalue</InfoList>
            </InfoListWrapper>

            <InfoListWrapper>
              <InfoList>{pet.weight}</InfoList>
            </InfoListWrapper>
          </InfoWrapper>
        </PetList>
      ))}
    </SectionWrapper>
  );
}
