import { useForm } from "dux/utils/formUtils/useForm";
import { Button, Form, Text } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import { createLoadingSelector } from "core/selectors/utils";
import associateActionTypes from "core/actionTypes/associateActionTypes";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { history } from "dux/utils/browser/browserHistory";
import { isFromCheckInOut } from "core/utils/validationUtils/isFromValidation";
import {
  getCheckInOutNewFoodNumber,
  getCheckInOutPetFoodOptions,
  getFoodFormData,
  getIsCheckInOutPetFoodFormValid,
} from "dux/_components/checkInOutFeeding/CheckInOutCartFoodSelectors";
import { get } from "lodash/fp";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { CHECK_IN_OUT_FOOD_OPTIONS } from "web/foodType/foodTypeConstants";
import { selectFoodList } from "@/dux/foodOptionsList/foodOptionsListSelectors";
import { getPetSpeciesId } from "core/selectors/ui/vaccinations/vaccinationsSelectors";
import { color } from "web/common/styles/theme";
import { getFoodIsShowingDetailsForType } from "web/foodList/foodListSelectors";
import { medicationTypes } from "web/newMedication/newMedicationConstants";
import { ClickOutside } from "core/utils/clickOutsideUtils/clickOutside";
import {
  clearPendingFoodById,
  setPendingFood,
  saveReservationCartDetails,
} from "dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import {
  getHotelItinerary,
  selectIsReservationDisabled,
} from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { selectCheckInOutDateRangeForFrequencyByPet } from "dux/hotelEngagements/hotelEngagementSelectors";
import CommonModal from "web/common/modals/commonModal";
import SetFrequencyModalContainer from "dux/frequency/modal/SetFrequencyModalContainer";
import {
  clearPendingFrequency,
  setPendingFrequency,
  setPendingFrequencyFoodDates,
  setPendingId,
} from "dux/frequency/actions/frequencyActions";
import {
  frequencyErrors,
  frequencyTypes,
  frequencyConstants,
} from "dux/frequency/FrequencyConstants";
import { getIsNotAddingNewFood } from "web/newFood/selectors/newFoodSelector";
import { clearNewFood, hideAddNewFood } from "web/newFood/actions/newFoodActions";
import { NEW_FOOD_ID } from "web/newFood/newFoodConstants";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "core/constants/validationErrors";
import { stripEmojis } from "core/utils/stringUtils/unicodeUtils";
import { v4 as uuidv4 } from "uuid";
import { HotelCheckInOutFoodRemoveButton } from "@/dux/hotelRemoveButton/HotelRemoveButton";
import {
  getPendingFrequency,
  getPendingFrequencyFoodDates,
  getPendingId,
} from "@/dux/frequency/frequencySelectors";
import { checkIfFrequencyUpdated } from "@/core/utils/frequencyUtils/checkIfFrequencyUpdated";
import { getFrequencyAndDates } from "@/core/utils/frequencyUtils/getFrequencyAndDates";
import { isValidString } from "@/core/utils/validationUtils/stringValidation";
import { minimizeWhitespace } from "@/core/utils/stringUtils/stringModificationUtils";
import { isValidFrequency } from "@/core/utils/validationUtils/validateFrequency";
import FoodSaveToProfileCheckboxContainer from "@/dux/bookingFeeding/bookingSaveFoodToProfile/FoodSaveToProfileCheckboxContainer";
import { getFoodSaveToProfileById } from "@/dux/bookingFeeding/bookingSaveFoodToProfile/foodSaveToProfileSelectors";
import { createPetFood } from "@/web/food/actions/createUpdateDeleteFoodActions";
import { formatFoodFromCheckIn } from "@/web/food/foodUtils";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutGrid } from "@/layout/grid/Grid";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { showHideFoodDetails } from "@/web/foodList/actions/foodListActions";
import { getFoodIsDiscontinuedById } from "@/web/food/foodsSelector";
import { CheckInOutPetProfileFoodDropdown } from "@/dux/checkInOutPetProfileFoodMedDropdown/CheckInOutPetProfileFoodMedDropdown";

const CheckInOutFoodForm = ({
  componentId,
  foodFormData,
  foodIndex,
  foodTypeOptions,
  foodList,
  speciesId,
  isDisabled,
  isRemoveDisabled,
  isRemoveButtonHidden,
  isHidden,
  onSubmit,
  onShowFrequencyModal,
  callHideNewFood,
  foodId,
  setFeedingFormInvalid,
  invalidFeedingForms = [],
  isNotAddingNewFood,
  clearNewFood,
  petId,
  clearFrequency = () => {},
  pendingFrequency,
  pendingFrequencyDates,
  customFrequencyDates,
  defaultFrequency,
  saveToProfile,
  hideFoodForm = () => {},
}) => {
  const {
    didFormFieldsUpdate,
    values,
    setFormInput,
    setFormValues,
    setTextAreaInput,
    setFormValue,
  } = useForm(foodFormData, minimizeWhitespace);

  const {
    foodType,
    foodName,
    otherFoodName,
    foodAmount,
    morning,
    afternoon,
    evening,
    foodInstructions,
    frequency = defaultFrequency,
    petProfileFoodId,
  } = values;

  // Store separately from form values as the form uses shallow
  // equality to check if fields updated and dates are in an array
  const [frequencyDates] = useState(customFrequencyDates ?? []);

  const foodNameOptions = useMemo(
    () =>
      getCheckInOutPetFoodOptions({
        foods: foodList,
        petSpecies: speciesId,
        foodType,
      }),
    [foodList, foodType, speciesId],
  );

  const [isModalShowing, setModalShowing] = useState(false);
  const [disableClickOutside, setDisableClickOutside] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const isFormValid = useMemo(
    () =>
      getIsCheckInOutPetFoodFormValid({ values, dates: pendingFrequencyDates ?? frequencyDates }),
    [
      foodType,
      foodName,
      otherFoodName,
      foodAmount,
      morning,
      afternoon,
      evening,
      frequency,
      pendingFrequencyDates,
    ],
  );

  // If the form gets closed, reset the form values and error state
  useEffect(() => {
    if (isHidden) {
      setFormValues(foodFormData);
      setShowErrors(false);

      if (foodId === NEW_FOOD_ID) {
        callHideNewFood();
        if (setFeedingFormInvalid)
          setFeedingFormInvalid(invalidFeedingForms?.filter(form => form !== foodId));
      }
    }
  }, [isHidden]);

  useEffect(() => {
    if (foodId === NEW_FOOD_ID && isNotAddingNewFood) {
      return;
    }

    if (!isFormValid && invalidFeedingForms?.find(form => form === foodId)) {
    } else if (!isFormValid) {
      if (setFeedingFormInvalid) setFeedingFormInvalid([...invalidFeedingForms, foodId]);
    } else if (setFeedingFormInvalid)
      setFeedingFormInvalid(invalidFeedingForms?.filter(form => form !== foodId));
  }, [isFormValid, isNotAddingNewFood]);

  useEffect(() => {
    if (foodId === NEW_FOOD_ID) {
      callHideNewFood();
    }
  }, [petId]);

  const ref = useRef(null);

  const onClickOutside = () => {
    const didFrequencyOrDatesUpdate = checkIfFrequencyUpdated(
      frequency,
      frequencyDates,
      pendingFrequency,
      pendingFrequencyDates,
    );
    const didUpdate = didFormFieldsUpdate || didFrequencyOrDatesUpdate || saveToProfile;

    if (isDisabled || !didUpdate || (foodId === NEW_FOOD_ID && isNotAddingNewFood)) {
      return;
    }

    // Sanitize user inputs
    const sanitizedValues = {
      ...values,
      foodInstructions: !!values.foodInstructions ? stripEmojis(values.foodInstructions) : "",
    };

    if (isFormValid) onSubmit(sanitizedValues);
    setShowErrors(true);
  };

  ClickOutside(ref, onClickOutside, isModalShowing || disableClickOutside);
  return !isHidden ? (
    <div id={componentId} ref={ref}>
      <LayoutBox>
        <LayoutStack space="scale-G1">
          <CheckInOutPetProfileFoodDropdown
            foodId={foodId}
            petProfileFoodId={petProfileFoodId}
            onChange={food => setFormValues({ ...values, ...food })}
          />

          <LayoutGrid
            style={{ gridTemplateColumns: "repeat(2, 1fr)", columnGap: "1em", paddingRight: "1em" }}
          >
            <LayoutStack>
              <Form.Field.Select
                id={`${componentId}__foodType`}
                legacy
                labelStyle={{ fontWeight: "normal" }}
                label={`Food type ${foodIndex}`}
                name="foodType"
                variant="material"
                options={foodTypeOptions}
                onChange={e => {
                  if (foodType === e.value) return;
                  // Set food type and clear food name since type is changing
                  setFormValue("foodType", e.value);
                  setFormValue("foodName", "");
                  setFormValue("otherFoodName", "");
                  setFormValue("petProfileFoodId", "");
                }}
                value={foodType}
                isDisabled={isDisabled}
              />
              {showErrors && !isValidString(foodType) && (
                <Text id={`${componentId}__requiredField--foodType`} color="text-color-red">
                  {REQUIRED_FIELD_ERROR_MESSAGE}
                </Text>
              )}
            </LayoutStack>
            <LayoutStack>
              <Form.Field.Select
                id={`${componentId}__foodName`}
                legacy
                labelStyle={{ fontWeight: "normal" }}
                label={`Food ${foodIndex} name`}
                name="foodName"
                error
                variant="material"
                options={foodNameOptions}
                onChange={e => {
                  if (foodName === e.label) return;
                  setFormValue("foodName", e.label);
                  setFormValue("externalId", e.value);
                  setFormValue("petProfileFoodId", "");

                  // clear otherName if value goes from other to a different value
                  if (e.label !== "Other" && foodName === "Other") {
                    setFormValue("otherFoodName", "");
                  }
                }}
                value={foodName}
                isDisabled={isDisabled}
              />
              {showErrors && !isValidString(foodName) && (
                <Text id={`${componentId}__requiredField--foodName`} color="text-color-red">
                  {REQUIRED_FIELD_ERROR_MESSAGE}
                </Text>
              )}
            </LayoutStack>
            <LayoutStack>
              <Form.Field.Text
                id={`${componentId}__otherFoodName`}
                label="Other food name"
                name="otherFoodName"
                onChange={setFormInput}
                value={otherFoodName}
                variant="material"
                labelStyle={
                  foodName !== "Other"
                    ? { color: color.gray300, fontWeight: "normal" }
                    : { fontWeight: "normal" }
                }
                disabled={isDisabled || foodName !== "Other"}
                style={{ color: isDisabled ? color.gray600 : "inherit" }}
              />
              {showErrors && !isValidString(otherFoodName) && foodName === "Other" && (
                <Text id={`${componentId}__requiredField--otherFoodName`} color="text-color-red">
                  {REQUIRED_FIELD_ERROR_MESSAGE}
                </Text>
              )}
            </LayoutStack>
            <LayoutStack>
              <Form.Field.Text
                id={`${componentId}__foodAmount`}
                label={`Food ${foodIndex} amount`}
                name="foodAmount"
                variant="material"
                onChange={setFormInput}
                labelStyle={{ fontWeight: "normal" }}
                value={foodAmount}
                disabled={isDisabled}
                style={{ color: isDisabled ? color.gray600 : "inherit" }}
              />
              {showErrors && !isValidString(foodAmount) && (
                <Text color="text-color-red">{REQUIRED_FIELD_ERROR_MESSAGE}</Text>
              )}
            </LayoutStack>
          </LayoutGrid>

          <LayoutBox padding="scale-0">
            <LayoutStack>
              <Text id={`${componentId}__foodTimeOfDay--label`} size="text-size-m">
                Food {foodIndex} time of day
              </Text>
              <LayoutCluster style={{ justifyContent: "flex-start" }} space="scale-G1">
                <LayoutBox padding="scale-0">
                  <LayoutCluster>
                    <Form.Field.Checkbox
                      id={`${componentId}__foodTimeOfDay--morning`}
                      name="morning"
                      checked={morning}
                      onClick={() => setFormValue("morning", !morning)}
                      disabled={isDisabled}
                    />
                    <Text>Morning</Text>
                  </LayoutCluster>
                </LayoutBox>
                <LayoutBox padding="scale-0">
                  <LayoutCluster>
                    <Form.Field.Checkbox
                      id={`${componentId}__foodTimeOfDay--afternoon`}
                      name="afternoon"
                      checked={afternoon}
                      onClick={() => setFormValue("afternoon", !afternoon)}
                      disabled={isDisabled}
                    />
                    <Text>Afternoon</Text>
                  </LayoutCluster>
                </LayoutBox>
                <LayoutBox padding="scale-0">
                  <LayoutCluster>
                    <Form.Field.Checkbox
                      id={`${componentId}__foodTimeOfDay--evening`}
                      name="evening"
                      checked={evening}
                      onClick={() => setFormValue("evening", !evening)}
                      disabled={isDisabled}
                    />
                    <Text>Evening</Text>
                  </LayoutCluster>
                </LayoutBox>
              </LayoutCluster>
              {showErrors && !morning && !afternoon && !evening && (
                <Text id={`${componentId}__requiredField--foodTimeOfDay`} color="text-color-red">
                  {REQUIRED_FIELD_ERROR_MESSAGE}
                </Text>
              )}
            </LayoutStack>
          </LayoutBox>

          <LayoutBox padding="scale-0">
            <LayoutStack>
              <Text id={`${componentId}__foodInstructions--label`} size="text-size-m,">
                Food {foodIndex} instructions
              </Text>
              <Form.Field.TextArea
                id={`${componentId}__foodInstructions`}
                style={{
                  border: "1px solid black",
                  fontSize: "1.15rem",
                  fontFamily: "Lato",
                  color: isDisabled ? color.gray600 : "inherit",
                }}
                width="100%"
                rows={4}
                name="foodInstructions"
                value={foodInstructions}
                onInput={setTextAreaInput}
                disabled={isDisabled}
              />
            </LayoutStack>
          </LayoutBox>

          <FoodSaveToProfileCheckboxContainer foodId={foodId} petId={petId} />

          <LayoutBox padding="scale-0">
            <LayoutStack>
              <LayoutCluster style={{ justifyContent: "flex-start" }}>
                <Button
                  id={`${componentId}__frequency--button`}
                  onClick={() => {
                    setModalShowing(true);
                    onShowFrequencyModal({ frequency });
                  }}
                  variant="prism-primary"
                  disabled={isDisabled}
                >
                  Set Frequency
                </Button>
                <Button
                  variant="no-outline"
                  onClick={() => {
                    if (foodId === NEW_FOOD_ID) clearNewFood();
                    setFormValues(foodFormData);
                    clearFrequency();
                    setFeedingFormInvalid(invalidFeedingForms?.filter(form => form !== foodId));
                    hideFoodForm({ petId, foodId });
                  }}
                  disabled={isDisabled}
                >
                  <Text id={`${componentId}__cancel--button`} color="text-color-blue">
                    Cancel
                  </Text>
                </Button>
              </LayoutCluster>
              {showErrors && !isValidFrequency(frequency, pendingFrequency ?? frequencyDates) && (
                <Text id={`${componentId}__requiredField--foodFrequency`} color="text-color-red">
                  {frequencyErrors.REQUIRED}
                </Text>
              )}
            </LayoutStack>
          </LayoutBox>

          <LayoutBox padding="scale-0">
            <HotelCheckInOutFoodRemoveButton
              foodId={foodId}
              foodIndex={foodIndex}
              isDisabed={isRemoveDisabled}
              isHidden={isRemoveButtonHidden}
              petId={petId}
              onClick={() => setDisableClickOutside(true)}
              onConfirm={() => setDisableClickOutside(false)}
            />
          </LayoutBox>
        </LayoutStack>
        {isModalShowing && (
          <CommonModal
            id={`${componentId}__commonModal--foodFrequency`}
            onClose={() => {
              setModalShowing(false);
            }}
          >
            <SetFrequencyModalContainer
              id={`${componentId}__setFoodFrequencyModalContent`}
              onClose={() => {
                setModalShowing(false);
              }}
            />
          </CommonModal>
        )}
      </LayoutBox>
    </div>
  ) : null;
};

export const CheckInOutNewFood = connect(
  (state, { setFeedingFormInvalid, invalidFeedingForms }) => {
    const petId = getCurrentPet(state);
    const itineraryId = getHotelItinerary(state)?.itineraryId;
    const foodId = NEW_FOOD_ID;
    const foodIndex = getCheckInOutNewFoodNumber(state, { petId });
    const isNotAddingNewFood = getIsNotAddingNewFood(state);
    const customerKey = getCurrentCustomerKey(state);
    const isReservationDisabled = selectIsReservationDisabled(state);
    const usePendingFrequency = getPendingId(state) === foodId;
    const { customFrequencyDates, ...foodFormData } = getFoodFormData(state, {
      foodId,
      petId,
    });

    return {
      componentId: `checkInOut_foodForm--${foodId}`,
      customerKey,
      hotelItineraryId: itineraryId,
      isDisabled: isReservationDisabled,
      isRemoveDisabled: isReservationDisabled,
      isRemoveButtonHidden: true,
      isLoading: createLoadingSelector([associateActionTypes.UPDATE_ASSOCIATE])(state),
      isHidden: !isFromCheckInOut(get(["location", "pathname"], history)) || isNotAddingNewFood,
      foodFormData,
      foodNameOptions: getCheckInOutPetFoodOptions(state, { foodId, petId }),
      foodTypeOptions: CHECK_IN_OUT_FOOD_OPTIONS,
      foodList: selectFoodList(state),
      speciesId: getPetSpeciesId(state, { petId }),
      foodIndex,
      petId,
      foodId,
      setFeedingFormInvalid,
      invalidFeedingForms,
      isNotAddingNewFood,
      pendingAppointmentDates: selectCheckInOutDateRangeForFrequencyByPet(state, { petId }),
      pendingFrequency: usePendingFrequency && getPendingFrequency(state),
      pendingFrequencyDates: usePendingFrequency && getPendingFrequencyFoodDates(state),
      defaultFrequency: frequencyConstants.DAILY,
      saveToProfile: getFoodSaveToProfileById(state, { foodId, petId }),
    };
  },
  dispatch => ({
    setPendingFoods: ({ petId, data, customerKey, hotelItineraryId, saveToProfile }) => {
      const foodData = { ...data, groupingId: uuidv4() };
      dispatch(setPendingFood({ foodData, foodId: NEW_FOOD_ID, petId }));
      // On outside click - make API call to save data
      dispatch(
        saveReservationCartDetails({
          petId,
          hotelItineraryId,
          customerKey,
        }),
      );

      if (saveToProfile)
        dispatch(
          createPetFood({ food: formatFoodFromCheckIn(foodData), petId, isFromBooking: true }),
        );
    },
    clearPendingFood: () => dispatch(clearPendingFoodById({ foodId: NEW_FOOD_ID })),
    setFrequency: ({ foodId, petId, frequency, dates = [] }) => {
      dispatch(setPendingId({ pendingId: foodId, petId, frequencyType: frequencyTypes.FOOD }));
      dispatch(setPendingFrequency({ frequency }));
      dispatch(setPendingFrequencyFoodDates({ dates }));
    },
    hideNewFood: ({ petId }) => {
      dispatch(hideAddNewFood({ petId }));
      dispatch(clearNewFood({ petId }));
    },
    clearFrequency: () => dispatch(clearPendingFrequency()),
    hideFoodForm: ({ foodId, petId }) =>
      dispatch(showHideFoodDetails({ foodId, petId, locationTypes: [] })),
  }),
  (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
    onShowFrequencyModal: () => {
      const { setFrequency } = dispatchProps;
      const {
        petId,
        foodId,
        foodFormData,
        pendingAppointmentDates,
        pendingFrequency,
        pendingFrequencyDates,
        defaultFrequency,
      } = stateProps;

      const { frequency, dates } = getFrequencyAndDates({
        frequency: foodFormData?.frequency,
        pendingFrequency,
        pendingDates: pendingFrequencyDates,
        appointmentDates: pendingAppointmentDates,
        defaultFrequency,
      });

      setFrequency({
        foodId,
        petId,
        frequency,
        dates,
      });
    },
    callHideNewFood: () => {
      const { hideNewFood } = dispatchProps;
      const { petId } = stateProps;
      hideNewFood({ petId });
    },
    clearNewFood: () => {
      const { clearPendingFood, hideNewFood } = dispatchProps;
      const { petId } = stateProps;
      clearPendingFood();
      hideNewFood({ petId });
    },
    onSubmit: values => {
      const { setPendingFoods, clearPendingFood, hideNewFood, setFrequency } = dispatchProps;
      const {
        petId,
        foodId,
        customerKey,
        hotelItineraryId,
        pendingFrequency,
        pendingFrequencyDates,
        pendingAppointmentDates,
        defaultFrequency,
        saveToProfile,
      } = stateProps;

      const { frequency, dates } = getFrequencyAndDates({
        pendingFrequency,
        pendingDates: pendingFrequencyDates,
        appointmentDates: pendingAppointmentDates,
        defaultFrequency,
      });

      setPendingFoods({
        data: {
          ...values,
          frequency,
          customFrequencyDates: dates,
        },
        petId,
        customerKey,
        hotelItineraryId,
        saveToProfile,
      });

      hideNewFood({ petId });
      clearPendingFood();
      setFrequency({ foodId, petId, frequency: defaultFrequency });
    },
  }),
)(CheckInOutFoodForm);

export const CheckInOutFoodList = connect(
  (state, { foodId, foodIndex, setFeedingFormInvalid, invalidFeedingForms }) => {
    const petId = getCurrentPet(state);
    const itineraryId = getHotelItinerary(state)?.itineraryId;
    const isShowingDetails = getFoodIsShowingDetailsForType(state, {
      foodId,
      petId,
      locationType: medicationTypes.CART,
    });
    const customerKey = getCurrentCustomerKey(state);
    const isReservationDisabled = selectIsReservationDisabled(state);
    const usePendingFrequency = getPendingId(state) === foodId;
    const { customFrequencyDates, ...foodFormData } = getFoodFormData(state, {
      foodId,
      petId,
    });

    const isDiscontinued = getFoodIsDiscontinuedById(state, { petId, foodId });

    return {
      componentId: `checkInOut_foodForm--${foodId}`,
      customerKey,
      hotelItineraryId: itineraryId,
      isDisabled: isReservationDisabled || isDiscontinued,
      isRemoveDisabled: isReservationDisabled,
      isRemoveButtonHidden: false,
      isLoading: createLoadingSelector([associateActionTypes.UPDATE_ASSOCIATE])(state),
      isHidden: !isFromCheckInOut(get(["location", "pathname"], history)) || !isShowingDetails,
      foodFormData,
      foodNameOptions: getCheckInOutPetFoodOptions(state, { foodId, petId }),
      foodTypeOptions: CHECK_IN_OUT_FOOD_OPTIONS,
      foodList: selectFoodList(state),
      speciesId: getPetSpeciesId(state, { petId }),
      foodIndex,
      petId,
      foodId,
      setFeedingFormInvalid,
      invalidFeedingForms,
      pendingAppointmentDates: selectCheckInOutDateRangeForFrequencyByPet(state, { petId }),
      pendingFrequency: usePendingFrequency && getPendingFrequency(state),
      pendingFrequencyDates: usePendingFrequency && getPendingFrequencyFoodDates(state),
      customFrequencyDates,
      defaultFrequency: frequencyConstants.DAILY,
      saveToProfile: getFoodSaveToProfileById(state, { foodId, petId }),
    };
  },
  dispatch => ({
    setPendingFoods: ({ foodId, petId, data, customerKey, hotelItineraryId, saveToProfile }) => {
      const foodData = { ...data };
      dispatch(setPendingFood({ foodData, foodId, petId }));
      // On outside click - make API call to save data
      dispatch(
        saveReservationCartDetails({
          petId,
          hotelItineraryId,
          customerKey,
        }),
      );

      if (saveToProfile)
        dispatch(
          createPetFood({ food: formatFoodFromCheckIn(foodData), petId, isFromBooking: true }),
        );
    },
    setFrequency: ({ foodId, petId, frequency, dates = [] }) => {
      dispatch(setPendingId({ pendingId: foodId, petId, frequencyType: frequencyTypes.FOOD }));
      dispatch(setPendingFrequency({ frequency }));
      dispatch(setPendingFrequencyFoodDates({ dates }));
    },
    clearFrequency: () => dispatch(clearPendingFrequency()),
    hideFoodForm: ({ foodId, petId }) =>
      dispatch(showHideFoodDetails({ foodId, petId, locationTypes: [] })),
  }),
  (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
    onShowFrequencyModal: () => {
      const { setFrequency } = dispatchProps;
      const {
        petId,
        foodId,
        foodFormData,
        pendingAppointmentDates,
        pendingFrequency,
        pendingFrequencyDates,
        customFrequencyDates,
        defaultFrequency,
      } = stateProps;

      const { frequency, dates } = getFrequencyAndDates({
        frequency: foodFormData?.frequency,
        frequencyDates: customFrequencyDates,
        pendingFrequency,
        pendingDates: pendingFrequencyDates,
        appointmentDates: pendingAppointmentDates,
        defaultFrequency,
      });

      setFrequency({
        foodId,
        petId,
        frequency,
        dates,
      });
    },
    onSubmit: values => {
      const { setPendingFoods } = dispatchProps;
      const {
        petId,
        foodId,
        customerKey,
        hotelItineraryId,
        pendingFrequency,
        pendingFrequencyDates,
        pendingAppointmentDates,
        customFrequencyDates,
        defaultFrequency,
        saveToProfile,
      } = stateProps;

      const { frequency, dates } = getFrequencyAndDates({
        frequency: values?.frequency,
        frequencyDates: customFrequencyDates,
        pendingFrequency,
        pendingDates: pendingFrequencyDates,
        appointmentDates: pendingAppointmentDates,
        defaultFrequency,
      });

      setPendingFoods({
        data: {
          ...values,
          frequency,
          customFrequencyDates: dates,
        },
        petId,
        foodId,
        customerKey,
        hotelItineraryId,
        saveToProfile,
      });
    },
  }),
)(CheckInOutFoodForm);
