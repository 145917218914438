import React from "react";
import styled from "styled-components";
import { color, font } from "../../../../common/styles/theme";

export default function QuickViewCustomerCard(props) {
  // Styles via styled-components
  const SectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* border-bottom: 1px solid ${color.gray200}; */
  `;

  const InfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 53px;
  `;

  const InfoListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  `;

  const InfoList = styled.div`
    font-family: ${font.mainFamily};
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.07;
    letter-spacing: normal;
    color: ${color.black0};
  `;

  const TempImageName = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 25px;
    box-sizing: border-box;
    width: 140px;
    height: 183px;
    background-image: url("https://via.placeholder.com/140x183");
  `;

  // Component JSX
  return (
    <SectionWrapper>
      <TempImageName> Customer Name </TempImageName>

      <InfoWrapper>
        <InfoListWrapper>
          {props.column1.map((item, index) => (
            <InfoList key={`col1Info_${index}`}>{item}</InfoList>
          ))}
        </InfoListWrapper>

        <InfoListWrapper>
          {props.column2.map((item, index) => (
            <InfoList key={`col2Info_${index}`}>{item}</InfoList>
          ))}
        </InfoListWrapper>
      </InfoWrapper>
    </SectionWrapper>
  );
}
