import React from "react";
import styled from "styled-components";
import ResizableTextarea from "./formInputs/ResizableTextarea";
import ErrorText from "./ErrorText";
import { color } from "./styles/theme";
import { minimizeWhitespace } from "@/core/utils/stringUtils/stringModificationUtils";

const Container = styled.div`
  display: ${props => (props.display ? props.display : "auto")};
  color: ${props => {
    if (props.error) {
      return "red";
    } else if (props.isFocused) {
      return color.blue500;
    } else if (props.disabled) {
      return color.gray400;
    }
    return "black";
  }};

  pointer-events: ${props => props.disabled && "none"};
`;

class InputWithBlur extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      isFocused: false,
    };
  }

  onBlur(value) {
    const { onBlur, currentValue } = this.props;
    if (currentValue !== value) {
      onBlur(value);
    }
    this.setState({ touched: true, isFocused: false });
  }

  onChange(value) {
    const { onBlur } = this.props;
    onBlur(value);
  }

  onFocus() {
    this.setState({ isFocused: true });
  }

  render() {
    const {
      componentId,
      title,
      display,
      isHidden,
      error,
      disabled,
      maxLength,
      currentValue,
    } = this.props;
    const { touched, isFocused } = this.state;
    const showError = touched && error;
    if (!isHidden) {
      return (
        <Container
          error={showError}
          isFocused={isFocused}
          disabled={disabled}
          display={display}
          id={componentId}
        >
          {title}
          <ResizableTextarea
            onFocus={() => this.onFocus()}
            onBlur={e => this.onBlur(e.target.value)}
            value={currentValue}
            onChange={e => this.onChange(minimizeWhitespace(e.target.value))}
            error={showError}
            disabled={disabled}
            maxLength={maxLength}
          />
          {showError && <ErrorText message={error} />}
        </Container>
      );
    }
    return null;
  }
}

export default InputWithBlur;
