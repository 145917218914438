import { connect } from "react-redux";
import { getFoodCount } from "../../foodCount/foodCountSelector";
import ClickableText from "../../common/ClickableText";
import { color } from "../../common/styles/theme";
import { hideAddNewFood, clearNewFood } from "../../newFood/actions/newFoodActions";
import { NEW_FOOD_ID } from "../../newFood/newFoodConstants";

const mapStateToProps = (state, ownProps) => {
  const { foodId, petId } = ownProps;
  const foodCount = getFoodCount(state, { petId });
  return {
    foodId,
    petId,
    foodCount,
    isVisible: foodId === NEW_FOOD_ID,
    label: "Cancel",
    styleObj: { color: color.blue500, padding: "10px" },
  };
};

const mapDispatchToProps = dispatch => ({
  cancelNewFood: (foodCount, petId) => {
    dispatch(hideAddNewFood({ petId }));
    dispatch(clearNewFood({ petId }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const { foodCount, petId } = propsFromState;
    propsFromDispatch.cancelNewFood(foodCount, petId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ClickableText);
