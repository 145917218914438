import React, { useEffect, useRef } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Text } from "@prism/psm-ui-components";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { ClickOutside } from "core/utils/clickOutsideUtils/clickOutside";
import Calendar from "web/common/Calendar";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { getHotelItinerary } from "dux/hotelItinerary/hotelItinerarySelectors";
import { selectCheckInOutDateTimeCommonState } from "./hotelCheckInOutSelectors";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { CALENDAR_FORMAT } from "@/core/constants/dateConstants";

const HotelCheckInOutDateComponent = props => {
  const {
    isHidden,
    componentId,
    displayDateTime,
    originalDateTime,
    changeTime,
    isEditing,
    setIsEditing,
    time,
    setTime,
    petId,
    dateIsOutsideRange = () => false,
  } = props;

  useEffect(() => {
    setIsEditing(false);
  }, [petId, originalDateTime?.format()]);

  // If the section is closed, then clear pending changes
  useEffect(() => {
    const isDateUpdated = !moment(originalDateTime).isSame(time, "day");
    if (!isEditing && isDateUpdated) {
      setTime(moment(originalDateTime));
    }
  }, [isEditing]);

  const ref = useRef(null);

  const onClickOutside = () => {
    changeTime(moment(time, "YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
  };

  ClickOutside(ref, onClickOutside);

  if (!isHidden) {
    return (
      <LayoutCluster
        id={componentId}
        space="scale-S2"
        flexWrap="flex-noWrap"
        style={{ overflow: "visible", justifyContent: "center" }}
      >
        {!isEditing ? (
          <Text>{displayDateTime}</Text>
        ) : (
          <div ref={ref}>
            <Calendar
              isOutsideRange={dateIsOutsideRange}
              selectedDate={time}
              selectDate={val => {
                const m = moment(originalDateTime, "HH:mm");
                const selectedDateTime = moment(val).set({
                  hour: m.hour(),
                  minutes: m.minute(),
                });
                setTime(selectedDateTime);
              }}
            />
          </div>
        )}
      </LayoutCluster>
    );
  }

  return null;
};

export const HotelCheckInDate = connect(
  (state, { time, changeTime, setTime, isEditing, setIsEditing }) => {
    const petId = getCurrentPet(state);
    const storeNumber = getHotelItinerary(state)?.storeNumber;
    const { startDateTime } = selectCheckInOutDateTimeCommonState(state, {
      petId,
      storeNumber,
    });
    return {
      componentId: "HotelCheckInDate",
      isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
      displayDateTime: startDateTime && startDateTime.calendar(null, CALENDAR_FORMAT),
      originalDateTime: startDateTime,
      setIsEditing,
      changeTime,
      isEditing,
      setTime,
      petId,
      time,
    };
  },
)(HotelCheckInOutDateComponent);

export const HotelCheckOutDate = connect(
  (state, { time, changeTime, setTime, isEditing, setIsEditing }) => {
    const petId = getCurrentPet(state);
    const storeNumber = getHotelItinerary(state)?.storeNumber;
    const { endDateTime } = selectCheckInOutDateTimeCommonState(state, {
      petId,
      storeNumber,
    });

    return {
      componentId: "HotelCheckOutDate",
      isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
      displayDateTime: endDateTime && endDateTime.calendar(null, CALENDAR_FORMAT),
      originalDateTime: endDateTime,
      setIsEditing,
      changeTime,
      isEditing,
      setTime,
      petId,
      time,
    };
  },
)(HotelCheckInOutDateComponent);
